import { useEffect } from "react"
import { useRouter } from "next/router"
// import { headers } from 'next/headers'

export default function Custom404() {
  const router = useRouter()
//   const headersList = headers()
//   const referer = headersList.get('referer')

  useEffect(() => {
    router.replace("/")
    // router.replace(referer)
  })

  return null
}